<template>
    <div id="performance">

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Prestaties</h1>
                    </div>
                </div>
            </div>

            <div class="performance-settings">
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                        <DatePicker v-model="from" @input="dateChanged()" />
                    </div>
                    <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                        <DatePicker v-model="till" @input="dateChanged()" />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <MultiSelect
                            v-model="userIds"
                            :options="selectableUsers.map(su => ({label: su.name, value: su.id}))"
                            track-by="id"
                            label="name"
                            placeholder="Selecteer een of meerdere medewerkers"
                            select-label=""
                            deselect-label=""
                            selected-label=""
                            :multiple="true"
                            :close-on-select="false"
                            :limit="12"
                        >
                        </MultiSelect>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="styled-checkbox">
                            <label>
                                <input type="checkbox" v-model="compareWithPrevYear">
                                <span>Vergelijken met dezelfde week vorig jaar</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 text-right">
                        <button class="btn btn-success" style="width:100%" @click="setData()">Zoeken</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mx-auto px-4">
            <div class="row">
                <div class="col" style="position: relative">
                    <Loader v-if="showUserInfoLoader" />
                    <div v-else class="employee-performance-holder">
                        <div class="employee-performance" v-for="(user, index) of showUsers" :key="index">
                            <h3>{{ user.name }}</h3>

                            <UserInfo :prev="false" :taskHours="taskHours" :harvestRegistrations="harvestRegistrations" :user="user" :products="products" :productTasks="productTasks" :contents="contents" :types="types" />

                            <UserInfo v-if="compareWithPrevYearAfterSearch" :prev="true" :taskHours="prevTaskHours" :harvestRegistrations="prevHarvestRegistrations" :user="user" :products="products" :productTasks="productTasks" :contents="contents" :types="types" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <template v-if="this.harvestRegistrations.length > 0">
                <div class="employees-average-performance">
                    <div class="row">
                        <div class="col-10">
                            Gemiddelde 'omzet per uur' van alle oogstmedewerkers samen over de geselecteerde periode
                        </div>
                        <div class="col-2">
                            {{ formatMoney(totalAveragePerHour(false)) }}
                        </div>
                    </div>
                    <template v-if="compareWithPrevYearAfterSearch">
                        <hr/>
                        <div class="row">
                            <div class="col-10">
                                Gemiddelde 'omzet per uur' van alle oogstmedewerkers samen over de geselecteerde periode een jaar eerder
                            </div>

                            <div class="col-2">
                                {{ formatMoney(totalAveragePerHour(true)) }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>


            <div v-if="! showUserInfoLoader" style="position: relative; height:430px;">
                <Line
                    v-if="chartData"
                    :data="chartData"
                    :options="{
                        responsive: true,
                        maintainAspectRatio: false,
                        aspectRatio: 3,
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }"
                />
            </div>

            <template v-if="tillFullWeek.diff(fromFullWeek, 'week') > 0 && ! showUserInfoLoader && employeePresence.length > 0">
                <div class="widget-holder">
                    <div class="row">
                        <div class="col">
                            <div class="performance-table-holder">
                                <table class="performance-table">
                                    <thead class="styled-table-header">
                                        <tr>
                                            <th>Huidige periode</th>
                                            <th>Ma</th>
                                            <th>Di</th>
                                            <th>Wo</th>
                                            <th>Do</th>
                                            <th>Vr</th>
                                            <th>Za</th>
                                            <th>Zo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-tooltip="'Kijkt naar alle ZZPers'">Gemiddeld aantal medewerkers</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 0) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 0).hours)*10.0)/10 : 0 }}</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 1) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 1).hours)*10.0)/10 : 0 }}</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 2) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 2).hours)*10.0)/10 : 0 }}</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 3) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 3).hours)*10.0)/10 : 0 }}</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 4) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 4).hours)*10.0)/10 : 0 }}</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 5) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 5).hours)*10.0)/10 : 0 }}</td>
                                            <td>{{ employeePresence.find(ep => ep.day_of_week == 6) ? Math.round(parseFloat(employeePresence.find(ep => ep.day_of_week == 6).hours)*10.0)/10 : 0 }}</td>
                                        </tr>
                                        <tr>
                                            <td v-tooltip="'Dit zijn alle aanwezige uren min pauze en taakuren van de geselecteerde medewerkers'">Gemiddeld aantal uren gebost</td>
                                            <td>{{ this.formatHours(round(
                                                graphWorkSessions.filter(
                                                    ws => ws.toDate('starts_at').format('E') === '1'
                                                ).map(
                                                    ws => ws.hoursWithoutBreak()
                                                ).reduce((a, b) => a + b, 0)
                                                /
                                                (tillFullWeek.diff(fromFullWeek, 'week')+1), 1
                                            )) }}</td>
                                            <td>{{ this.formatHours(round(graphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '2').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round(graphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '3').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round(graphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '4').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round(graphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '5').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round(graphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '6').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round(graphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '7').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                        </tr>
                                        <tr>
                                            <td v-tooltip="'Dit zijn alle taakuren van de geselecteerde medewerkers'">Gemiddeld aantal taak uren</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '1').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600) / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '2').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '3').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '4').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '5').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '6').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                            <td>{{ this.formatHours(round((graphTaskHours.filter(th => th.date.format('E') === '7').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (tillFullWeek.diff(fromFullWeek, 'week')+1), 1)) }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <!-- TABEL JAAR EERDER -->
                    <template v-if="tillFullWeek.diff(fromFullWeek, 'week') > 0 && compareWithPrevYearAfterSearch">
                        <div class="row">
                            <div class="col">
                                <div class="performance-table-holder" style="margin-top: 25px;">
                                    <table class="performance-table" v-if="tillFullWeek.diff(fromFullWeek, 'week') > 0">
                                        <thead class="styled-table-header">
                                            <tr>
                                                <th>Jaar eerder</th>
                                                <th>Ma</th>
                                                <th>Di</th>
                                                <th>Wo</th>
                                                <th>Do</th>
                                                <th>Vr</th>
                                                <th>Za</th>
                                                <th>Zo</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td v-tooltip="'Kijkt naar alle ZZPers'">Gemiddeld aantal medewerkers</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 0) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 0).hours)*10.0)/10 : 0 }}</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 1) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 1).hours)*10.0)/10 : 0 }}</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 2) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 2).hours)*10.0)/10 : 0 }}</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 3) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 3).hours)*10.0)/10 : 0 }}</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 4) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 4).hours)*10.0)/10 : 0 }}</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 5) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 5).hours)*10.0)/10 : 0 }}</td>
                                                <td>{{ prevEmployeePresence.find(ep => ep.day_of_week == 6) ? Math.round(parseFloat(prevEmployeePresence.find(ep => ep.day_of_week == 6).hours)*10.0)/10 : 0 }}</td>
                                            </tr>
                                            <tr>
                                                <td v-tooltip="'Dit zijn alle aanwezige uren min pauze en taakuren van de geselecteerde medewerkers'">Gemiddeld aantal uren gebost</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '1').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '2').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '3').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '4').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '5').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '6').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round(prevGraphWorkSessions.filter(ws => ws.toDate('starts_at').format('E') === '7').map(ws => ws.hoursWithoutBreak()).reduce((a, b) => a + b, 0) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                            </tr>
                                            <tr>
                                                <td v-tooltip="'Dit zijn alle taakuren van de geselecteerde medewerkers'">Gemiddeld aantal taak uren</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '1').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600) / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '2').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '3').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '4').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '5').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '6').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                                <td>{{ this.formatHours(round((prevGraphTaskHours.filter(th => th.date.format('E') === '7').map(th => th.seconds_worked).reduce((a, b) => a + b, 0) / 3600)  / (prevTillFullWeek.diff(prevFromFullWeek, 'week')+1), 1)) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import User from '../../models/user';
import HarvestRegistration from '../../models/harvest-registration';
import WorkSession from '../../models/work-session';
import TaskHour from '../../models/task-hour';
import Task from '../../models/task';
import { mapState } from 'pinia';
import UserInfo from './UserInfo.vue';
import { useCcstStore } from '../../stores/ccst';
import MultiSelect from '../../components/Form/MultiSelect.vue';
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineController, LineElement } from 'chart.js'
import Loader from './../../components/Loader.vue';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineController, LineElement)

export default {
  components: { UserInfo, MultiSelect, Line, Loader },

    data: function(){
        setTimeout(() => {
            this.setData();
        }, 200);

        return {
            borderColors: ['#007bff', '#dc3545', '#28a745', '#ffc107', '#17a2b8', '#dc3545', '#28a745', '#20c997', '#6c757d', '#e83e8c', '#deb731', '#9c27b0'],

            averageBorderColor: '#000',

            averagePrevYearBorderColor: '#F00',

            userIds: [],
            showUsers: [],
            tasks: [],
            compareWithPrevYear: false,
            compareWithPrevYearAfterSearch: false,

            from: (new moment()).subtract(9, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
            till: (new moment()).subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'),
            today: (new moment()),

            /* from: (new moment('2024-07-01', 'YYYY-MM-DD')).toDate(),
            till: (new moment('2024-07-31', 'YYYY-MM-DD')).toDate(), */

            fromFullWeek: null,
            tillFullWeek: null,

            prevFromFullWeek: null,
            prevTillFullWeek: null,

            harvestRegistrations: [],
            workSessions: [],
            taskHours: [],

            prevHarvestRegistrations: [],
            prevWorkSessions: [],
            prevTaskHours: [],

            products: [],
            productTasks: [],

            selectableUsers: [],

            graphData: false,
            graphHarvestRegistrations: [],
            graphWorkSessions: [],
            graphTaskHours: [],

            prevGraphHarvestRegistrations: [],
            prevGraphWorkSessions: [],
            prevGraphTaskHours: [],

            showUserInfoLoader: false,

            employeePresence: [],
            prevEmployeePresence: []
        }
    },

    computed: {
        chartData() {return this.graphData},

        ...mapState(useCcstStore, ['chests', 'contents', 'sizes', 'types']),
    },

    methods: {

        addYear(minus = false)
        {
            if (minus) {
                this.from = moment(this.from, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DD');
                this.till = moment(this.till, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DD');
            } else {
                this.from = moment(this.from, 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM-DD');
                this.till = moment(this.till, 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM-DD');
            }

            this.setFullWeeks();
        },

        customFormatter(date) {
            return moment(date).format('DD-MM-YYYY') + ' week ' + moment(date).format('W');
        },

        totalAveragePerHour(prev)
        {
            let money = this[prev ? 'prevHarvestRegistrations' : 'harvestRegistrations'].reduce((summedNumber, object) =>
                (summedNumber + (object.money * object.harvested)),
            0);
            let hoursPresence = this[prev ? 'prevWorkSessions' : 'workSessions'].reduce((sum, o) => o.hoursWithoutBreak(), 0);
            let taskHours = this[prev ? 'prevTaskHours' : 'taskHours'].reduce((sum, o) => sum + o.seconds_worked, 0);
            taskHours = ((taskHours !== 0) ? (taskHours / 3600) : 0);

            return (money / (hoursPresence - taskHours)) / 100;
        },

        dateChanged()
        {
            this.setFullWeeks();
        },

        setFullWeeks()
        {
            let from = moment(this.from).subtract(1, 'day').startOf('week').add(3, 'day');
            let till = moment(this.till).subtract(1, 'day').startOf('week').add(3, 'day');

            let cur = moment().subtract(1, 'day').startOf('week').add(3, 'day');

            if (from.isoWeek() === cur.isoWeek()) {
                from.subtract(1, 'week');
            }

            if (till.isoWeek() === cur.isoWeek()) {
                till.subtract(1, 'week');
            }

            from.startOf('week').add(1, 'day');
            till.endOf('week').add(1, 'day');

            this.fromFullWeek = from.clone();
            this.tillFullWeek = till.clone();

            var fromDay = (1 + ((moment(this.from).isoWeek()) - 1) * 7); // 1st of January + 7 days for each week
            var tillDay = (1 + ((moment(this.till).isoWeek()) - 1) * 7);

            this.prevFromFullWeek = this.fromFullWeek.clone().subtract(1, 'year').startOf('week').add(1, 'day');
            this.prevTillFullWeek = this.tillFullWeek.clone().startOf('week').subtract(1, 'year').endOf('week').add(1, 'day');
        },

        setData()
        {
            this.showUsers = this.selectableUsers.filter(su => this.userIds.includes(su.id));
            this.compareWithPrevYearAfterSearch = this.compareWithPrevYear;
            this.harvestRegistrations = [];
            this.workSessions = [];
            this.taskHours = [];

            this.prevHarvestRegistrations = [];
            this.prevWorkSessions = [];
            this.prevTaskHours = [];

            this.graphData = false;
            this.graphHarvestRegistrations = [];
            this.graphTaskHours = [];
            this.graphWorkSessions = [];

            this.prevGraphHarvestRegistrations = [];
            this.prevGraphTaskHours = [];
            this.prevGraphWorkSessions = [];

            this.employeePresence = [];
            this.prevEmployeePresence = [];

            if (this.showUsers.length > 0 && this.from !== null && this.till !== null) {
                this.showUserInfoLoader = true;
                this.showGraphLoader = true;

                this.getData().then(response => {
                    this.combineHarvestRegistrationsAndPrices('harvestRegistrations');

                    if (this.compareWithPrevYearAfterSearch) {
                        this.combineHarvestRegistrationsAndPrices('prevHarvestRegistrations');
                    }

                    for(let user of this.showUsers) {
                        user.moneyEarned = this.moneyEarned(user.id, false);
                        user.harvestingHours = this.harvestingHours(user.id, false);
                        user.avgHarvestingHourPerDay = user.harvestingHours / this.workSessions.filter(ws => ws.user_id === user.id).length;
                        user.moneyPerHour = user.moneyEarned / user.harvestingHours;

                        if (this.compareWithPrevYearAfterSearch) {
                            user.prevMoneyEarned = this.moneyEarned(user.id, true);
                            user.prevHarvestingHours = this.harvestingHours(user.id, true);
                            user.prevAvgHarvestingHourPerDay = user.prevHarvestingHours / this.prevWorkSessions.filter(ws => ws.user_id === user.id).length;
                            user.prevMoneyPerHour = user.prevMoneyEarned / user.prevHarvestingHours;
                        }
                    }

                    this.showUsers = _.orderBy(this.showUsers, 'moneyPerHour', 'desc');
                    let microSizeId = this.sizes.find(size => size.name === 'mini').id;

                    // Get all unique products from harvestRegistrations and sort them by content_id.
                    this.products = _.map(this.harvestRegistrations, hr => [hr.content_id, hr.type_id, (hr.size_id === microSizeId)]);
                    if (this.compareWithPrevYearAfterSearch) {
                        this.products = _.concat(this.products, _.map(this.prevHarvestRegistrations, hr => [hr.content_id, hr.type_id, (hr.size_id === microSizeId)]))
                    }
                    this.products = new Set(this.products.map(JSON.stringify));
                    this.products = Array.from(this.products).map(JSON.parse);
                    this.products.sort((a, b) => a[0] > b[0] ? 1 : -1);

                    // Get all unique tasks from harvestRegistrations and sort them by content_id.
                    let task_ids = _.uniq(this.taskHours.map(th => th.task_id));
                    this.productTasks = this.tasks.filter(task => task_ids.includes(task.id));
                    this.showUserInfoLoader = false;

                    this.setGraphData();
                })
            }
        },

        getData()
        {
            let betweens = [{
                after: (moment(this.from) <= this.fromFullWeek ? moment(this.from) : this.fromFullWeek).format('YYYY-MM-DD 00:00:00'),
                before: (moment(this.till) >= this.tillFullWeek ? moment(this.till) : this.tillFullWeek).format('YYYY-MM-DD 23:59:59')
            }];

            if (this.compareWithPrevYearAfterSearch) {
                let fromSubtracted = moment(this.from).subtract(1, 'year')
                let tillSubtracted = moment(this.till).subtract(1, 'year')

                betweens.push({
                    after: (fromSubtracted <= this.prevFromFullWeek ? fromSubtracted : this.prevFromFullWeek).format('YYYY-MM-DD 00:00:00'),
                    before: (tillSubtracted >= this.prevTillFullWeek ? tillSubtracted : this.prevTillFullWeek).format('YYYY-MM-DD 23:59:59')
                })
            }

            betweens = JSON.stringify(betweens);

            let queryString = {
                user_ids: _.map(this.showUsers, 'id').join(','),
                betweens
            };
            queryString = Object.keys(queryString).map(key => key + '=' + queryString[key]).join('&');

            let promises = [
                axios.get('harvest-registrations?' + queryString).then(response => {
                    for (let harvestRegistrationData of response.data.data) {
                        let model = this.setModel(new HarvestRegistration(), harvestRegistrationData, 'harvestRegistrations', 'graphHarvestRegistrations', 'prevHarvestRegistrations', 'prevGraphHarvestRegistrations');
                    }
                }),
                axios.get('work-sessions?' + queryString).then(response => {
                    for (let workSessionData of response.data.data) {
                        let model = this.setModel(new WorkSession(), workSessionData, 'workSessions', 'graphWorkSessions', 'prevWorkSessions', 'prevGraphWorkSessions');
                    }
                }),
                axios.get('task-hours?' + queryString).then(response => {
                    for (let taskHourData of response.data.data) {
                        let model = this.setModel(new TaskHour(), taskHourData, 'taskHours', 'graphTaskHours', 'prevTaskHours', 'prevGraphTaskHours');
                    }
                }),
                axios.get('work-sessions/summary?' + queryString).then(response => {
                    this.employeePresence = response.data.current;

                    if ('prev' in response.data) {
                        this.prevEmployeePresence = response.data.prev;
                    }
                })
            ];

            return Promise.all(promises);
        },

        setGraphData()
        {
            if (this.tillFullWeek.diff(this.fromFullWeek, 'week') > 0) {
                this.graphData = {
                    'labels': [],
                    'datasets': []
                };

                this.combineHarvestRegistrationsAndPrices('graphHarvestRegistrations');

                if (this.compareWithPrevYearAfterSearch) {
                    this.combineHarvestRegistrationsAndPrices('prevGraphHarvestRegistrations');
                    this.setGraphDataPerAverage()
                } else {
                    this.setGraphDataPerUser();
                    this.setGraphDataPerAverage();
                }
                this.showGraphLoader = false;
            }  else {
                this.graphData = false;
            }
            this.showGraphLoader = false;
        },

        setGraphDataPerAverage()
        {
            let start = this.fromFullWeek.clone();

            this.graphData.datasets.push({
                label: 'Geselecteerde periode',
                data: [],
                borderColor: this.averageBorderColor,
                pointBackgroundColor: 'rgba(128,128,128,0.35)',
                fill: false,
            });

            while (start <= this.tillFullWeek) {
                const {moneyEarned, hoursPresence, taskHours} = this.getMoneyEarnedAndHoursPresenceAndTaskHours(start, false);
                /* const startOfWeek = start.clone();
                const endOfWeek = start.clone().add(6, 'day').endOf('day');

                const moneyEarned = this.graphHarvestRegistrations
                    .filter(hr => hr.created_at.isBetween(startOfWeek, endOfWeek))
                    .reduce((sum, hr) => (sum + (hr.money * hr.harvested)), 0) / 100;

                const hoursPresence = this.graphWorkSessions
                    .filter(ws => ws.toDate('starts_at').isBetween(startOfWeek, endOfWeek))
                    .reduce((sum, ws) => (sum + ws.hoursWithoutBreak()), 0);

                const taskHours = this.graphTaskHours
                    .filter(th => th.date.isBetween(startOfWeek, endOfWeek))
                    .reduce((sum, th) => (sum +th.seconds_worked), 0) / 3600; */

                //let moneyEarned = _.sum(this.graphHarvestRegistrations.filter(hr => hr.created_at.isBetween(startOfWeek, endOfWeek)).map(hr => hr.money * hr.harvested)) / 100
                //let hoursPresence = _.sumBy(this.graphWorkSessions.filter(ws => ws.toDate('starts_at').isBetween(startOfWeek, endOfWeek)), ws => ws.hoursWithoutBreak());
                //let taskHours = _.sumBy(this.graphTaskHours.filter(th => th.date.isBetween(startOfWeek, endOfWeek)), 'seconds_worked') / 3600;

                this.graphData.datasets[this.graphData.datasets.length - 1].data.push(this.round(moneyEarned > 0 ? moneyEarned / (hoursPresence - taskHours) : 0, 2));

                start.add(1, 'week');
            }

            if (this.compareWithPrevYearAfterSearch){
                start = this.prevFromFullWeek.clone();
                let till = this.prevTillFullWeek.clone();

                this.graphData.datasets.push({
                    label: 'Een jaar eerder',
                    data: [],
                    borderColor: this.averagePrevYearBorderColor,
                    pointBackgroundColor: 'rgba(128,128,128,0.35)',
                    fill: false,
                });

                while (start <= till) {
                    this.graphData['labels'].push('Week ' + start.isoWeek());
                    const {moneyEarned, hoursPresence, taskHours} = this.getMoneyEarnedAndHoursPresenceAndTaskHours(start, true);

                    this.graphData.datasets[1].data.push(this.round(moneyEarned > 0 ? moneyEarned / (hoursPresence - taskHours) : 0, 2));

                    start.add(1, 'week');
                }
            }
        },

        getMoneyEarnedAndHoursPresenceAndTaskHours(start, prev = false) {
            let startOfWeek = start.clone();
            let endOfWeek = start.clone().add(6, 'day').endOf('day');

            const moneyEarned = this[`${prev ? 'prevG' : 'g'}raphHarvestRegistrations`]
                .filter(hr => hr.created_at.isBetween(startOfWeek, endOfWeek))
                .reduce((sum, hr) => (sum + (hr.money * hr.harvested)), 0) / 100;

            const hoursPresence = this[`${prev ? 'prevG' : 'g'}raphWorkSessions`]
                .filter(ws => ws.toDate('starts_at').isBetween(startOfWeek, endOfWeek))
                .reduce((sum, ws) => (sum + ws.hoursWithoutBreak()), 0);

            const taskHours = this[`${prev ? 'prevG' : 'g'}raphTaskHours`]
                .filter(th => th.date.isBetween(startOfWeek, endOfWeek))
                .reduce((sum, th) => (sum +th.seconds_worked), 0) / 3600;


            return {moneyEarned, hoursPresence, taskHours};
        },

        setGraphDataPerUser()
        {
            let start = this.fromFullWeek.clone();

            for (let user of this.showUsers) {
                this.graphData.datasets.push({
                    label: user.name,
                    data: [],
                    borderColor: this.borderColors[this.graphData.datasets.length],
                    pointBackgroundColor: 'rgba(128,128,128,0.35)',
                    fill: false,
                });
            }

            while (start <= this.tillFullWeek) {
                this.graphData['labels'].push(start.format('YYYY') +' week ' + start.isoWeek());
                let startOfWeek = start.clone();
                let endOfWeek = start.clone().add(6, 'day').endOf('day');

                for (let index in this.showUsers) {
                    let user = this.showUsers[index];

                    const moneyEarned = _.sum(this.graphHarvestRegistrations.filter(hr => (
                        hr.user_id === user.id
                        && hr.created_at.isBetween(startOfWeek, endOfWeek)
                    )).map(hr => hr.money * hr.harvested)) / 100

                    const hoursPresence = _.sumBy(this.graphWorkSessions.filter(ws => (
                        ws.user_id === user.id
                        && ws.toDate('starts_at').isBetween(startOfWeek, endOfWeek)
                    )), ws => ws.hoursWithoutBreak());

                    let taskHours = (_.sumBy(this.graphTaskHours.filter(th => (
                        th.user_id === user.id
                        && th.date.isBetween(startOfWeek, endOfWeek)
                    )), 'seconds_worked'));
                    taskHours = taskHours !== 0 ? taskHours / 3600 : 0;

                    this.graphData.datasets[index].data.push(this.round(moneyEarned > 0 ? moneyEarned / (hoursPresence - taskHours) : 0, 2));
                }
                start.add(1, 'week');
            }

        },

        combineHarvestRegistrationsAndPrices(harvestRegistrationsCollection)
        {
            let x20 = this.contents.find(content => content.name === 'x20').id;
            let x16 = this.contents.find(content => content.name === 'x16').id;
            let x15 = this.contents.find(content => content.name === 'x15').id;
            let x12 = this.contents.find(content => content.name === 'x12').id;
            let x10 = this.contents.find(content => content.name === 'x10').id;
            let x9 = this.contents.find(content => content.name === 'x9').id;
            let x8 = this.contents.find(content => content.name === 'x8').id;
            let x6 = this.contents.find(content => content.name === 'x6').id;

            let rainbows = this.types.filter(type => ['rainbow', 'rainbow_whitepoint', 'bonte'].includes(type.name)).map(type => type.id);
            let iwm = this.types.filter(type => ['icicles', 'whitepoint', 'mix'].includes(type.name)).map(type => type.id);
            let tygerLila = this.types.filter(type => ['tyger', 'purple_whitepoint'].includes(type.name)).map(type => type.id);
            let redId = this.types.find(type => type.name === 'red').id;
            let microSizeId = this.sizes.find(size => size.name === 'mini').id;

            let harvestRegistrations = this[harvestRegistrationsCollection];

            for (let hr of harvestRegistrations) {


                // IF: MINI/MICRO
                // ELSE NORMALE GROOTTE
                if(hr.size_id === microSizeId){
                    if (hr.content_id === x9) {hr.money = 162;}
                    else if (hr.content_id === x15) {hr.money = 272;}
                    else if (hr.content_id === x16) {hr.money = 290;}
                    else {hr.money = 210;}// Zou niet moeten kunnen maar ok
                } else  {
                    // IF: TYGER OR PURPLE WHITEPOINT AND x12
                    if (tygerLila.includes(hr.type_id) && hr.content_id === x12) {
                        hr.money = 120;
                    }
                    // IF: RAINBOW OR BONTE
                    // ELSE: NORMAAL (NON RAINBOW OF BONTE)
                    if (rainbows.includes(hr.type_id)) {
                        if (hr.content_id === x6) {hr.money = 82;}
                        else if (hr.content_id === x8) {hr.money = 108;}
                        else if (hr.content_id === x9) {hr.money = 122;}
                        else if (hr.content_id === x10 && hr.chest_id === 32) {hr.money = 120;}
                        else if (hr.content_id === x10) {hr.money = 136;}
                        else if (hr.content_id === x15) {hr.money = 203;}
                        else if (hr.content_id === x20) {hr.money = 220;}

                        else if (hr.content_id === x9) {hr.money = 81;}// Zou niet moeten kunnen maar ok
                        else {hr.money = 100;}// Zou niet moeten kunnen maar ok
                    } else if(iwm.includes(hr.type_id)) {
                        if (hr.content_id === x15) {hr.money = 151;}
                        else if (hr.content_id === x10) {hr.money = 100;}
                        else if (hr.content_id === x9) {hr.money = 90;}
                        else if (hr.content_id === x8) {hr.money = 80;}
                        else {hr.money = 100;}
                    } else {
                        if (hr.content_id === x15) {hr.money = 150;}
                        else if (hr.content_id === x10) {hr.money = 100;}
                        else if (hr.content_id === x9) {hr.money = 90;}
                        else if (hr.content_id === x8) {hr.money = 80;}
                        else {hr.money = 100;}
                    }
                }
            }
        },

        setModel(modelInstance, data, collection, graphCollection, prevCollection, prevGraphCollection) {
            let model = modelInstance;
            model.setAttributesFromResponse(data);

            if (model.isBetween(moment(this.from), moment(this.till).endOf('day'))) {
                this[collection].push(model);
            }

            if (model.isBetween(this.fromFullWeek, moment(this.tillFullWeek))) {
                this[graphCollection].push(model);
            }

            if (this.compareWithPrevYearAfterSearch) {
                if (model.isBetween(moment(this.from).subtract(1, 'year'), moment(this.till).endOf('day').subtract(1, 'year'))) {
                    this[prevCollection].push(model);
                }

                if (model.isBetween(this.prevFromFullWeek, this.prevTillFullWeek)) {
                    this[prevGraphCollection].push(model);
                }
            }

            return model.setAttributesFromResponse(data);
        },

        harvestingHours(user_id, prev) {

            let hoursPresence = _.sumBy(this[prev ? 'prevWorkSessions' : 'workSessions'].filter(ws => ws.user_id === user_id), ws => ws.hoursWithoutBreak());

            let taskHours = (_.sumBy(this[prev ? 'prevTaskHours' : 'taskHours'].filter(th => th.user_id === user_id), 'seconds_worked')+1) / 3600;

            return hoursPresence - taskHours;
        },

        moneyEarned(user_id, prev)
        {
            return _.sum(this[prev ? 'prevHarvestRegistrations' : 'harvestRegistrations'].filter(hr => hr.user_id === user_id).map(hr => {
                return hr.money * hr.harvested
            })) / 100;
        },

        round(float, decimals) {
            return (Math.round(float * Math.pow(10, decimals)) / Math.pow(10, decimals));
        },

        formatHours(hours)
        {
            hours = hours > 0 ? hours : 0;

            let hour = Math.floor(hours);
            let minutes = Math.round(60 * (hours - Math.floor(hours)));

            if (minutes === 60) {
                hour += 1;
                minutes = 0;
            }

            let formatted = String(hour).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');

            return (formatted === '00:00' ? '-' : formatted);
        },

        formatMoney(cents)
        {
            let euros = Math.floor(cents);
            cents = Math.round((cents - euros) * 100);

            return '€ ' + euros + ',' + String(cents).padStart(2,0);
        }
    },

    created() {
        axios.get('/users?has-role=harvest-worker,minis-harvest-worker&sort=firstname,insertion,surname').then(response => {
            for (let userData of response.data.data) {
                let user = new User();
                user.setAttributesFromResponse(userData);
                this.selectableUsers.push({id: user.id, name: user.name()});

            }
        });

        axios.get('tasks')
        .then(response => {
            for (let taskData of response.data.data) {
                let task = new Task();
                task.setAttributesFromResponse(taskData);
                this.tasks.push(task);
            }
        });

        this.setFullWeeks();
    }
};
</script>
